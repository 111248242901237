<template>
  <div>
    <div class="breadcrumb">
      <div style="font-size: 14px">
        <span>{{ mySearchBread }}</span>
      </div>
      <div style="padding: 5px">
        <i class="el-icon-close icon" @click="closeClick"></i>
      </div>
    </div>

    <div v-if="myIdentifier">
      <div
        style="
          display: flex;
          align-items: center;
          padding: 20px;
          border-bottom: 1px solid #e5e5e5;
        "
      >
        <div style="display: flex; flex: 1">
          <el-input
            v-model="QueryContent"
            placeholder="输入要查询的内容"
          ></el-input>
          <el-button
            style="margin-left: 5px"
            type="success"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>

        <div
          class="i"
          style="
            flex: 4;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
          "
        >
          <div v-for="(item, index) in couponZone" :key="item.title">
            <span>{{ item.title }}</span
            >：{{ item.frame }}张￥{{ item.price }}
            <i
              class="iconfont icon-bianji"
              @click="dialogBianji(item.title, index)"
            ></i>
            <i
              class="mt-5 iconfont icon-sousuo"
              @click="dialogSousuo(item.title)"
            ></i>
            <i class="iconfont icon-daochuFile"></i>
          </div>
          <div v-for="(item, index) in orderZone" :key="item.title">
            <span>{{ item.title }}</span
            >：
            <span v-if="!item.judge">{{ item.num }}桶</span>
            <span v-if="item.judge">￥{{ item.num }}元</span>
            <i
              class="iconfont icon-bianji"
              @click="dialogBianji(item.title, index)"
            ></i>
            <i
              class="mt-5 iconfont icon-sousuo"
              @click="dialogSousuo(item.title)"
            ></i>
            <i class="iconfont icon-daochuFile"></i>
          </div>
        </div>
      </div>

      <div class="file_form">
        <el-row>
          <el-col :span="3">
            <!-- 文件夹处 -->
            <div class="file">
              <div class="file_level1" @click="isFileShow = !isFileShow">
                <img
                  v-if="isFileShow"
                  style="width: 10px; height: 10px"
                  :src="require('@/assets/mianAssets/文件夹减.png')"
                  alt=""
                />
                <img
                  v-if="!isFileShow"
                  style="width: 10px; height: 10px"
                  :src="require('@/assets/mianAssets/文件夹加.png')"
                  alt=""
                />
                <img
                  style="width: 22px; height: 22px; margin-left: 5px"
                  :src="require('@/assets/mianAssets/文件夹.png')"
                  alt=""
                />
                <a href="#">全部文件</a>
              </div>
              <div class="file_level2" v-if="isFileShow">
                <ul>
                  <li
                    v-for="(file, index) in filStorages"
                    :key="file"
                    style="display: flex; align-items: center"
                    @click="fileClick(index)"
                  >
                    <img
                      style="width: 12px; margin-right: 3px"
                      :src="require('@/assets/mianAssets/空白页.png')"
                      alt=""
                    /><a href="#" :title="file"
                      ><span :class="isLabel == index ? 'label' : ''">{{
                        file
                      }}</span></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
          <el-col :span="21">
            <!-- 表格 -->
            <div style="padding: 10px 0">
              <el-table
                ref="multipleTable"
                border
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%; border: 1px solid #e5e5e5"
              >
                <el-table-column prop="id" sortable label="编号" align="center">
                </el-table-column>
                <el-table-column prop="name" label="名称" align="center">
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center">
                </el-table-column>
                <el-table-column
                  prop="purchasePrice"
                  sortable
                  label="采购价"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop=" inventory"
                  label="预购库存"
                  align="center"
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop="freight"
                  sortable
                  label="运费"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="barrelCoupon"
                  label="是否使用买桶券"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="roundCoupon"
                  label="是否使用三轮券"
                  align="center"
                >
                </el-table-column>
                <el-table-column label="管理" align="center">
                  <template slot-scope="scope">
                    <i
                      class="iconfont icon-bianji poiner"
                      @click="handleBianji(scope.row)"
                    ></i>
                    <i
                      class="mt-5 iconfont icon-sousuo poiner"
                      @click="handleSearch(scope.row)"
                    ></i>
                    <i
                      class="iconfont icon-daochuFile poiner"
                      @click="handleDaochu(scope.row)"
                    ></i>
                  </template>
                </el-table-column>
              </el-table>
              <div class="tableBottom">
                <span v-if="isResult" style=""
                  >显示第&nbsp;1&nbsp;至&nbsp;{{
                    tableFinshNum
                  }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
                >
                <span v-if="!isResult" style=""
                  >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
                    tableFinshNum
                  }}&nbsp;项</span
                >
                <div
                  style="
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                  "
                >
                  <el-button>首页</el-button>
                  <el-button>上页</el-button>
                  <el-pagination
                    background
                    layout=" pager,slot"
                    :total="1"
                  ></el-pagination>
                  <el-button>下页</el-button>
                  <el-button>末页</el-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 功能区的编辑 -->
      <el-dialog
        :visible.sync="dialogVisibleRedact"
        width="30%"
        :before-close="handleClose"
      >
        <span slot="title" class="dialog-footer">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ dialogCrumb }}</el-breadcrumb-item>
            <el-breadcrumb-item>发起审核</el-breadcrumb-item>
          </el-breadcrumb>
        </span>
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="库存：" prop="inventory">
              <el-input v-model="ruleForm.inventory"></el-input>
            </el-form-item>
            <el-form-item label="操作：" prop="operate">
              <el-radio-group v-model="ruleForm.operate">
                <el-radio label="加库存"></el-radio>
                <el-radio label="减库存"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="修改原因" prop="cause">
              <el-input
                type="textarea"
                :rows="5"
                v-model="ruleForm.cause"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>

      <!-- 功能区的查询 -->
      <el-dialog
        :visible.sync="dialogVisibleSearch"
        :before-close="handleClose"
      >
        <span slot="title" class="dialog-footer">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>商品资料</el-breadcrumb-item>
            <el-breadcrumb-item>库存</el-breadcrumb-item>
            <el-breadcrumb-item>{{ dialogCrumb }}</el-breadcrumb-item>
          </el-breadcrumb>
        </span>
        <div>
          <div style="display: flex; align-items: center">
            <span>使用时间：</span>
            <el-date-picker
              type="date"
              v-model="startDate"
              ref="startDate"
              style="width: 20%"
              :picker-options="pickerOptions1"
            ></el-date-picker>
            <span style="margin: 0 5px">-</span>
            <el-date-picker
              type="date"
              v-model="endDate"
              style="width: 20%"
              :picker-options="pickerOptions2"
            ></el-date-picker>
            <div style="display: flex; margin-left: 10px">
              <el-button type="success" icon="iconfont icon-sousuo"
                >查询</el-button
              >
              <el-button type="success" icon="el-icon-delete">清除</el-button>
              <el-button type="primary" icon="iconfont icon-daochuFile"
                >导出报表</el-button
              >
            </div>
          </div>
          <div style="padding: 20px">
            <el-table
              ref="multipleTable"
              border
              :data="dialogVisibleSearchDable"
              tooltip-effect="dark"
              style="width: 100%; border: 1px solid #e5e5e5"
            >
              <el-table-column prop="date" sortable label="日期" align="center">
              </el-table-column>
              <el-table-column prop="user" label="操作人" align="center">
              </el-table-column>
              <el-table-column prop="remark" label="备注" align="center">
              </el-table-column>
              <el-table-column
                prop="serial"
                sortable
                label="订单编号"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="previousInventory"
                sortable
                label="上期库存"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="number"
                sortable
                label="数量"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="currentInventory"
                sortable
                label="本期库存"
                align="center"
              >
              </el-table-column>
            </el-table>
            <div class="tableBottom">
              <span v-if="isResult2" style=""
                >显示第&nbsp;1&nbsp;至&nbsp;{{
                  tableFinshNum2
                }}&nbsp;项结果，共&nbsp;{{ tableFinshNum2 }}&nbsp;项</span
              >
              <span v-if="!isResult2" style=""
                >显示&nbsp;{{ tableFinshNum2 }}&nbsp;项结果，共&nbsp;{{
                  tableFinshNum2
                }}&nbsp;项</span
              >
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <el-button>首页</el-button>
                <el-button>上页</el-button>
                <el-pagination
                  background
                  layout=" pager,slot"
                  :total="1"
                ></el-pagination>
                <el-button>下页</el-button>
                <el-button>末页</el-button>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </el-dialog>

      <!-- 表格区的编辑 -->
      <el-dialog
        :visible.sync="dialogVisTableRedact"
        width="30%"
        :before-close="handleClose"
      >
        <span slot="title" class="dialog-footer">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>产品档案</el-breadcrumb-item>
            <el-breadcrumb-item>编辑</el-breadcrumb-item>
          </el-breadcrumb>
        </span>
        <div>
          <el-form
            :model="ruleFormTableRedact"
            :rules="rulesTableRedact"
            label-width="150px"
            class="demo-ruleForm"
          >
            <el-form-item label="进货价：" prop="purchasingCost">
              <el-input v-model="ruleFormTableRedact.purchasingCost"></el-input>
            </el-form-item>
            <el-form-item label="预购库存：" prop="inventory">
              <el-input v-model="ruleFormTableRedact.inventory"></el-input>
            </el-form-item>
            <el-form-item label="进货运费(单件)：" prop="freight">
              <el-input v-model="ruleFormTableRedact.freight"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox-group v-model="ruleFormTableRedact.ticket" style="display:flex;flex-direction: column;">
                <el-checkbox label="使用买桶券" name="ticket"></el-checkbox>
                <el-checkbox label="使用三轮券" name="ticket"></el-checkbox>
                <el-checkbox label="是否高价水" name="ticket"></el-checkbox>
                <el-checkbox label="使用农夫代金券" name="ticket"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="修改原因" prop="cause">
              <el-input
                type="textarea"
                :rows="5"
                v-model="ruleFormTableRedact.cause"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleFormTableRedact')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>

      <!-- 表格中的查询 -->
      <el-dialog
        :visible.sync="dialogVisTableSearch"
        :before-close="handleClose"
      >
        <span slot="title" class="dialog-footer">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>商户资料</el-breadcrumb-item>
            <el-breadcrumb-item>库存</el-breadcrumb-item>
            <el-breadcrumb-item>商品明细</el-breadcrumb-item>
          </el-breadcrumb>
        </span>
        <div>
          <div style="display: flex; align-items: center">
            <span>使用时间：</span>
            <el-date-picker
              type="date"
              v-model="TableSearchStartDate"
              style="width: 20%"
              :picker-options="TableSearchpicker1"
            ></el-date-picker>
            <span style="margin: 0 5px">-</span>
            <el-date-picker
              type="date"
              v-model="TableSearchEndDate"
              style="width: 20%"
              :picker-options="TableSearchpicker2"
            ></el-date-picker>
            <div style="display: flex; margin-left: 10px">
              <el-button type="success" icon="iconfont icon-sousuo"
                >查询</el-button
              >
              <el-button type="success" icon="el-icon-delete">清除</el-button>
              <el-button type="primary" icon="iconfont icon-daochuFile"
                >导出报表</el-button
              >
            </div>
          </div>
          <div style="padding: 20px">
            <el-table
              ref="multipleTable"
              border
              :data="dialogVisTableSearchData"
              tooltip-effect="dark"
              style="width: 100%; border: 1px solid #e5e5e5"
            >
              <el-table-column prop="date" sortable label="日期" align="center">
              </el-table-column>
              <el-table-column prop="user" label="操作人" align="center">
              </el-table-column>
              <el-table-column prop="remark" label="备注" align="center">
              </el-table-column>
              <el-table-column
                prop="serial"
                sortable
                label="订单编号"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="previousInventory"
                sortable
                label="上期库存"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="number"
                sortable
                label="数量"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="currentInventory"
                sortable
                label="本期库存"
                align="center"
              >
              </el-table-column>
            </el-table>
            <div class="tableBottom">
              <span v-if="isResult3" style=""
                >显示第&nbsp;1&nbsp;至&nbsp;{{
                  tableFinshNum3
                }}&nbsp;项结果，共&nbsp;{{ tableFinshNum3 }}&nbsp;项</span
              >
              <span v-if="!isResult3" style=""
                >显示&nbsp;{{ tableFinshNum3 }}&nbsp;项结果，共&nbsp;{{
                  tableFinshNum3
                }}&nbsp;项</span
              >
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;
                "
              >
                <el-button>首页</el-button>
                <el-button>上页</el-button>
                <el-pagination
                  background
                  layout=" pager,slot"
                  :total="1"
                ></el-pagination>
                <el-button>下页</el-button>
                <el-button>末页</el-button>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  props: ["searchBread2", "identifier"],
  data() {
    return {
      QueryContent: "",
      barrelCoupon: 0,
      barrelCouponPrice: 0,

      isFileShow: false,
      filStorages: ["到店自提", "自提水票", "配送专区"],
      isLabel: -1,
      tableData: [
        {
          id: 1,
          pic: "",
          date: "",
          uid: 1,
          name: "",
          unit: "",
          classify: "",
          marketPrice: "",
          salesPrice: "",
          currentInventory: 0,
          availableStock: 0,
          sort: 0,
          salesStrategy: "",
          isPutaway: 0,
        },
        {
          id: 2,
          pic: "",
          date: "",
          uid: 2,
          name: "",
          unit: "",
          classify: "",
          marketPrice: "",
          salesPrice: "",
          currentInventory: 0,
          availableStock: 0,
          sort: 0,
          salesStrategy: "",
          isPutaway: 1,
        },
      ],
      isResult: false,
      tableFinshNum: 0,

      dialogVisibleRedact: false,
      couponZone: [
        { title: "买桶券", frame: 0, price: 0 },
        { title: "三轮券", frame: 0, price: 0 },
        { title: "农夫代金券", frame: 0, price: 0 },
      ],
      orderZone: [
        { title: "预购空桶", num: 0, judge: 0 },
        { title: "运费余额", num: 0, judge: 1 },
      ],
      dialogCrumb: "",
      ruleForm: {
        inventory: "",
        operate: "加库存",
        cause: "",
      },
      rules: {
        inventory: [{ required: true, message: "必填字段", trigger: "blur" }],
        cause: [{ required: true, message: "必填字段", trigger: "blur" }],
      },

      dialogVisibleSearch: false,
      startDate: "",
      endDate: "",
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      pickerOptions2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.startDate.getTime()
          ); // 禁用小于起始时间的日期
        },
      },
      dialogVisibleSearchDable: [],
      isResult2: false,
      tableFinshNum2: 0,

      dialogVisTableRedact: false,
      ruleFormTableRedact: {
        purchasingCost: "",
        inventory: "",
        freight: "",
        ticket: [],
        cause: "",
      },
      rulesTableRedact: {
        purchasingCost: [
          { required: true, message: "必填字段", trigger: "blur" },
        ],
        cause: [{ required: true, message: "必填字段", trigger: "blur" }],
      },


      dialogVisTableSearch:false,
      TableSearchStartDate: "",
      TableSearchEndDate: "",
      TableSearchpicker1: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      TableSearchpicker2: {
        disabledDate: (time) => {
          return (
            time.getTime() > Date.now() ||
            time.getTime() < this.TableSearchStartDate.getTime()
          ); // 禁用小于起始时间的日期
        },
      },
      dialogVisTableSearchData:[],
      isResult3:false,
      tableFinshNum3:0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }

    if (this.dialogVisibleSearchDable.length == 0) {
      this.isResult2 = false;
    } else {
      this.isResult2 = true;
      this.tableFinshNum2 = this.dialogVisibleSearchDable.length;
    }

    if (this.dialogVisTableSearchData.length == 0) {
      this.isResult3 = false;
    } else {
      this.isResult3 = true;
      this.tableFinshNum3 = this.dialogVisTableSearchData.length;
    }
  },
  computed: {
    mySearchBread() {
      return this.searchBread2;
    },
    myIdentifier() {
      return this.identifier;
    },
  },
  methods: {
    closeClick() {
      this.$emit("close", 0);
    },

    dialogBianji(val) {
      this.dialogVisibleRedact = true;
      this.dialogCrumb = val;
    },
    dialogSousuo(val) {
      this.dialogVisibleSearch = true;
      this.dialogCrumb = val;
      this.startDate = "";
      this.endDate = "";
    },
    handleClose() {
      this.dialogVisibleRedact = false;
      this.dialogVisibleSearch = false;
      this.dialogVisTableRedact = false;
      this.dialogVisTableSearch = false;
    },
    submitForm() {},

    fileClick(i) {
      this.isLabel = i;
      console.log("文件选中" + i);
    },
    handleBianji() {
      this.dialogVisTableRedact = true;
    },
    handleSearch() {
      this.dialogVisTableSearch = true;
    },
    handleDaochu() {},
  },
};
</script>
<style scoped lang="less">
.icon {
  cursor: pointer;
}

.icon:hover {
  color: red;
}

.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.i {
  i {
    color: #5a98de;
    cursor: pointer;
  }
  i:hover {
    color: #0066cc;
  }
  div {
    margin: 0 4% !important;
  }
}

.file_form {
  padding: 20px;
  .file {
    .file_level1 {
      display: flex;
      align-items: center;
      width: 120px;

      a {
        font-size: 14px;
        color: #333;
        font-family: "微软雅黑";
      }
    }

    .file_level2 {
      padding-left: 40px;
      margin-top: 3px;

      a {
        height: 20px;
        font-size: 14px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}
.tableBottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
.label {
  background-color: #ffeabf;
}
.poiner {
  cursor: pointer;
}

.mt-5 {
  margin: 0 5px !important;
}
</style>
